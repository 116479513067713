
    .product-card {
        display: flex;
        flex-direction: column;
        flex: 0 0 200px;
        width: 200px;

        .uk-card-media-top {
            img {

            }
        }

        .uk-card-body {
            display: flex;
            flex-direction: column;
        }

        .loyalty-discount {
            display: flex;
            align-items: center;
            height: auto;
            padding-top: calc(var(--global-margin) * 0.2);
            padding-bottom: calc(var(--global-margin) * 0.2);
            border-radius: var(--border-radius-small);

        }

        .product-info-wrapper {
            display: flex;
            flex-direction: column;

            .product-cta{
                margin-top: auto;
            }
        }

        .uk-card-media-top {
            width: 100%;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-height: 100%;
                max-width: 100%;
                width: 100%;
                height: auto;
                margin: auto;

            }
        }

        .old-price {
            position: relative;
            top: -4px;
            text-decoration: line-through;

        }

        &.product-card--large {
            flex: 0 0 350px;
            width: 350px;

            .uk-card-media-top {
                width: 100%;
                height: 350px;


                img {


                }
            }
        }

        &.product-card--micro {
            display: block;
            height: 175px;

            .uk-card-media-top {
                height: auto;
            }

            .product-meta {
                max-height: 90px;
            }

            .product-info-wrapper {
                margin: 0;
                p {
                    margin: 0;
                }
            }
            .uk-card-media-top {
                img {
                    margin-top: 0;
                }
            }

            .uk-card-title {
                font-size: calc(var(--global-font-size) * 1);
            }

            .product-meta {
                font-size: calc(var(--global-font-size) * 0.75);
            }

            .old-price {
                font-size: calc(var(--global-font-size) * 0.75);

            }
        }
    }
